<template>
	<div class="container-fluid relative animatedParent animateOnce my-3">
		<div class="tab-pane animated fadeInUpShort show active go">
			<div class="row my-3">
				<div class="col-md-12">
					<div class="card r-0 shadow">
						<div class="card-header">
							
							
							<div class="row my-3">
								<div class="col-md-6">
									<!-- 搜索 start -->
									<div class="input-group w-100 m-3 form-inline"><div class="input-group-btn">
										<input v-model="query" class="form-control p-2" placeholder="搜索" type="text">
										
										<button @click="search" type="button" class="btn btn-secondary ml-2"><i class="icon-search"></i>
										</button>
										</div>
									</div>
									<!-- 搜索 end -->
								</div>
								<div class="col-md-6 ">
									<div class="float-right m-3">
										<button  @click="add" type="button" class="btn btn-secondary">创建企业</button>
									</div>	
								</div>	
							</div>	
						</div>
	
						<div class="table-responsive">
							<div>
								<table class="table table-striped table-hover r-0 mb-0">
									<thead>
										<tr class="no-b">
											<th style="width: 60px">
												<!-- <div class="custom-control custom-checkbox">
													<input type="checkbox" id="checkedAll" class="custom-control-input"><label class="custom-control-label" for="checkedAll"></label>
												</div> -->
												 <div class="d-none d-lg-block">序号</div>
											</th>
											<th>
												<div class="d-none d-lg-block">企业</div>
											</th>
											<!-- <th>
												<div class="d-none d-lg-block">企业代码</div>
											</th> -->
											
											<th>
												<div class="d-none d-lg-block">企业ID</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">账户ID</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">激活链接</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">状态</div>
											</th>
											
											<th></th>
										</tr>
									</thead>
	
									<tbody>
										<tr v-for="(item,index) in departmentlist" v-bind:key="index" v-show="item.is_default != 1" 
										:class="{'blue lighten-5':selectedId == item.id}">
											<td>
												<!-- <div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input checkSingle" id="user_id_32" required=""><label class="custom-control-label" for="user_id_1"></label>
												</div> -->
												<div class="d-none d-lg-block">{{index+1}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.department_name}}
												<br>
												{{item.department_code}}
												</div>
											</td>
											
											<!-- <td>
												<div class="d-none d-lg-block">{{item.department_code}}</div>
											</td> -->
											
											<td>
												<div class="d-none d-lg-block">{{item.enterprise_id}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.account_id}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.sign_url}}</div>
											</td>
											
											
											
											<td>
												<div class="d-none d-lg-block">
													<span v-if="item.enabled==1" class="text-success">启用</span>
													<span v-if="item.enabled==0" class="text-danger">禁用</span>
												</div>
											</td>
											
											<td>
												<!-- <button @click.stop="edit(index)" type="button" class="btn btn-outline-primary btn-xs"> 额度设置 </button> -->
												
												<button @click.stop="edit(index)" type="button" class="btn btn-outline-primary btn-xs ml-2"> 编辑 </button>
												<button @click.stop="editPhoneSetting(index)" type="button" class="btn btn-outline-primary btn-xs ml-2"> 查看/设置审批手机 </button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
	
			<!-- <div class="row">
				<div class="col-sm-12 col-md-5">
					<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
						显示  {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
					</div>
				</div>
				<div class="col-sm-12 col-md-7">
					
					<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>
					
				</div>
			</div> -->
	
		</div>
		
		<!--Add New Message Fab Button-->
		<!-- <div @click="add" class="btn-fab btn-fab-md fab-right fab-right-bottom-fixed shadow btn btn-secondary"><i class="icon-add"></i></div> -->
		
		<!-- modal start -->
		<div class="modal fade" id="modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="exampleModalLabel">{{selectedIndex ==-1?'添加企业':'修改企业'}}</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        <div>
		          <div class="form-group">
		            <label for="">企业名称</label>
		            <input v-model="department.department_name" type="text" class="form-control"  placeholder="企业名称">
		            <div class="valid-feedback">
						
		            </div>
		          </div>
		          
				  <div class="form-group">
				    <label for="">企业代码</label>
				    <input v-model="department.department_code" type="text" class="form-control" placeholder="企业代码">
				    <small class="form-text text-muted">
						统一社会信用码
				    </small>
				  </div>
				  
				  <div class="form-group">
				    <label for="">企业ID</label>
				    <input v-model="department.enterprise_id" type="text" class="form-control" placeholder="企业ID">
				    <small class="form-text text-danger">
				    	即enterprise_id，非常重要
				    </small>
				  </div>
				  
				  <div class="form-group">
				    <label for="">账户ID</label>
				    <input v-model="department.account_id" type="text" class="form-control" placeholder="账户ID">
				    <small class="form-text text-danger">
				  		即account_id，非常重要
				    </small>
				  </div>
				  
				  <!-- 
				  <div class="form-group">
				    <label for="">激活链接</label>
				    <input v-model="department.sign_url" type="text" class="form-control" placeholder="激活链接">
				    <small class="form-text text-muted">
				    </small>
				  </div> -->
				  
				  <div class="form-group" v-show="department.id>0">
				    <input v-model="department.enabled" type="checkbox" name="status" value="1" class="">
				    <label for="">启用</label>
				  </div>
				  
		        </div>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
		        <button v-if="selectedIndex != -1" @click="removeReady(selectedIndex)" type="button" class="btn btn-danger"> 删除 </button>
				 <button @click="save" :disabled="disabled" type="button" class="btn btn-primary"> 保存 </button>
		      </div>
		    </div>
		  </div>
		</div>
		
		
		<!-- 审批手机 -->
		<div class="modal fade" id="pModal" tabindex="-1" aria-labelledby="" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="">审批手机设置</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        <div>
		          <div class="form-group">
		            <label for="" class="font-weight-bold">企业名称</label>
		            <input v-model="phone_setting.department_name" type="text" class="form-control-plaintext"  placeholder="企业名称">
		            <div class="valid-feedback">
						
		            </div>
		          </div>
		          
				  <div class="form-group">
				    <label for="" class="font-weight-bold">审批手机号码</label>
				    <input v-model="phone_setting.phone_number" type="text" class="form-control" placeholder="审批手机号码">
				    <small class="form-text text-danger">
						用于企业接收福利发放审批短信
				    </small>
				  </div>
				  
		        </div>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
				<button @click="savePhoneSetting" :disabled="disabled" type="button" class="btn btn-primary"> 保存 </button>
		      </div>
		    </div>
		  </div>
		</div>
		<!-- modal end -->
		
	</div>		
</template>

<script>
module.exports = {
    data: function() {
        return {
			title: '企业',
			
			token: '',
			
			// setting: {},
			// outletlist: [],
			
			ent_id: 0,
			dept_id: 0,
			
			ent_name: '',
			dept_name: '',
			user_role: '',
			user_name: '',
			
			departmentlist: [],
			query: '',
			
			department: {},
			selectedIndex: -1,
			
			selectedId: 0,
			
			disabled: false,
			
			////
			phone_setting: {
				department_name: '',
				phone_number: '',
			},
        }
    },
    props: {
      
    },
	
	// components: {
	// 	// 将组建加入组建库
	// 	// 'v-pagination': 'url:./component/my-page.vue',
	// 	'v-sibebar': 'url:/component/aside.vue',
	// 	'v-navbar': 'url:/component/navbar.vue',
	// 	'v-right-sidebar': 'url:/component/right-sidebar.vue'
	// },
	
	created: function() {
		console.log('--- created --- ')
		let self = this;
		
		//1.检查登录
		let token = localStorage.getItem('TOKEN');
		// if (!token) {
		// 	return;
		// }
		self.token = token;
		
		//2.取得店铺信息
		let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
		console.log(lsLoginData.dept_name)
		self.ent_id = lsLoginData.ent_id;
		console.info('--- this.$store.state ->',this.$store.state)
		
		// self.dept_id = this.$store.state.current_outlet_id || lsLoginData.dept_id;
		
		// self.ent_name = lsLoginData.ent_name;
		// self.dept_name = lsLoginData.dept_name;
		
		// self.user_name = lsLoginData.user_account;
		
		// self.setting = JSON.parse(localStorage.getItem('SETTING'));
		
		// self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
		// console.log('--- outlet list ->', self.outletlist)
	},
	mounted: function() {
		console.log('--- mounted --- ')
		let self = this;
		
		//1.检查登录
		// let token = localStorage.getItem('TOKEN');
		// if (!self.token) {
		// 	console.log('--- mounted jump  --- ')
		// 	location.href = 'index.html';
		// 	return;
		// }
		
		self.initData();
	},
    methods:{
    	initData() {
    		let self = this;
    	
    		//
    		this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
    				params: {
    					token: self.token,
    					// dept_id: self.dept_id,
    					// query: self.query,
    					// current_page: self.current_page,
    					// page_size: self.page_size,
    				}
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    				if (data.code == 200) {
    					self.departmentlist = data.data.departments;
    					__LIST = data.data.departments;
    				} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    		//END
    	
    	},
    	
    	search() {
    	
    		let self = this;
    		self.departmentlist = __LIST.filter(item => item.department_name.indexOf(self.query) != -1);
    	},
    	
    	add() {
    		var self = this;
    	
    		// location.href = 'editcategory.html';	
    	
    		self.selectedIndex = -1;
    		self.department = {
				id: 0,
				parent_department_id: "-1",
				enabled: 1,
			};
			
    		$('#modal').modal('show');
    	},
    	
    	edit(index) {
    		console.log(index);
    		let self = this;
    	
    		// let cg = self.departmentlist[index];
    	
    		self.selectedIndex = index;
    		self.department = Object.assign({}, self.departmentlist[index]);
			self.selectedId = self.department.id;
    		console.log(self.selectedIndex, self.department);
    	
    		self.oldName = self.department.department_name;
    	
    		$('#modal').modal('show');
    	},
    	
    	save() {
    		let self = this;
    		console.log(self.department);
    		// return;
    		console.log(self.oldName)
			
			if(self.disabled) return;
    	
    		//校验
    		if (!self.department.department_name) {
    			// alert('分类名称不能为空')
				this.$toasted.error('企业名称不能为空', {
					position: 'top-center',
				}).goAway(2000)
    			return;
    		}
			
			if (!self.department.department_code) {
				// alert('分类名称不能为空')
				this.$toasted.error('企业代码不能为空', {
					position: 'top-center',
				}).goAway(2000)
				return;
			}
    	
    		//更新时，如果没有改名，不提交
    // 		if (self.selectedIndex != -1 &&
    // 			self.department.department_name.toLowerCase() == self.oldName.toLowerCase()) {
    // 			// alert('分类名称没有更改')
				// this.$toasted.error('名称没有更改', {
				// 	position: 'top-center',
				// }).goAway(2000)
				
    // 			return;
    // 		}
    	
    		//同名校验
    		for (let i = 0; i < self.departmentlist.length; i++) {
    	
    			if (self.selectedIndex == i) continue; //编辑时，忽略自身
    	
    			if (self.departmentlist[i].department_name.toLowerCase() == self.department.department_name.toLowerCase()) {
    				// alert('分类名称不能不能重复');
					
					this.$toasted.error('企业名称不能不能重复', {
						position: 'top-center',
					}).goAway(2000)
					
    				// console.log(__LIST[self.selectedIndex].category_name)
    				self.department.department_name = self.oldName;
    				return;
    			}
    		}
    	
    	
    		self.disabled = true;
    	
    		// 组装数据
    		let postData = {
    			token: self.token,
				
    			enterprise_id: self.department.enterprise_id,
				account_id: self.department.account_id,
				sign_url: self.department.sign_url,
				
    			dept_id: self.department.id,//添加时为0
    			department_name: self.department.department_name,
				department_code: self.department.department_code,
				parent_department_id: "-1",//B企业，固定值-1（根部门）
				enabled: self.department.enabled?1:0,
				nonce_str: self.department.nonce_str || ''
    		}
			
			console.log('--- postData ->',postData)
    	
    		let _url = self.selectedIndex == -1 ? 'addDepartment' : 'updateDepartment';
    		this.axios.get(this.GLOBAL.baseURI + _url, {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    	
    				self.disabled = false;
    	
    				if (data.code == 200) {
						
						if(data.data.result_code == 'success'){
							if (self.selectedIndex == -1) {
								self.departmentlist.unshift(data.data.department);
							} else {
								// self.departmentlist.splice(self.selectedIndex, 1, data.data);
								console.log('--- 更新部门返回 data.data ->',data.data.department)
								self.$set(self.departmentlist, self.selectedIndex, data.data.department)
							}
							    	
							    	
							// alert('保存成功')
							self.$toasted.success('保存成功', {
								position: 'top-center',
							}).goAway(2000)
							    	
							$('#modal').modal('hide');
						}
						else{
							self.$toasted.error(data.data.result_msg, {
								position: 'top-center',
							}).goAway(2000)
						}
    					
    				} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) {
    					//登录超时
						$('#modal').modal('hide');
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    	},
		
		
		removeReady(index){
			console.log(index);
			let self = this;
			
			self.selectedIndex = index;
			self.department = Object.assign({}, self.departmentlist[index]);
			
			self.$confirm({
				title: '警告',
				message: '删除企业会连带删除部门，部门下有员工时，无法删除，确定删除？',
				button: {
					no: '取消',
					yes: '确定',
				},
				callback:(res)=>{
					console.log(res)
					if(res){
						self.remove();
					}
					
				}
			});
		},
    	
    	
    	remove() {
    		let self = this;
    		console.log(self.department);
    		// return;
    	
    		self.disabled = true;
    	
    		// 组装数据
    		let postData = {
    			token: self.token,
    			// enterprise_id: self.enterprise_id,//后端TOKEN中取得
				enterprise_id: self.department.enterprise_id,
    			dept_id: self.department.id,
				nonce_str: self.department.nonce_str || ''
    		}
    	
    		this.axios.get(this.GLOBAL.baseURI + 'delDepartment', {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    	
    				self.disabled = false;
    	
    				if (data.code == 200) {
    					if(data.data.result_code == 'success'){
							self.departmentlist.splice(self.selectedIndex, 1);
							    	
							self.$toasted.success('删除成功', {
								position: 'top-center',
							}).goAway(2000)
							    	
							$('#modal').modal('hide');
						}
						else{
							self.$toasted.error(data.data.result_msg, {
								position: 'top-center',
							}).goAway(2000)
						}
    				} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    	},
		
		editPhoneSetting(index) {
			console.log(index);
			let self = this;
		
			// let cg = self.departmentlist[index];
		
			self.selectedIndex = index;
			self.department = Object.assign({}, self.departmentlist[index]);
			self.selectedId = self.department.id;
			console.log(self.selectedIndex, self.department);
			
			//初始化phone_setting
			self.phone_setting = {
				department_name: self.department.department_name,
				phone_number: '',
			}
			
			self.getPhoneSetting(()=>{
				$('#pModal').modal('show');
			});
			
		},
		
		savePhoneSetting(){
			let self = this;
			
			if(!self.phone_setting.phone_number){
				self.$toasted.error( '请输入手机号', {
					position: 'top-center',
					duration: '2000',
				})
				return;
			}

			self.disabled = true;
			
			// 组装数据
			let postData = {};

			postData['token'] = self.token;
			postData["dept_id"] = self.selectedId;
			postData["phone_number"] = self.phone_setting.phone_number;
			
			let formData = new FormData();
			for (var key in postData) {
				console.log(key + ':' + postData[key]);
				formData.append(key, postData[key]);
			}

			//调用接口
			self.axios.post(self.GLOBAL.baseURI + 'savePhoneSetting',
					formData
				)
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log('--- error->', error);
					// alert('保存失败')
					self.$toasted.error( '保存失败', {
						position: 'top-center',
						duration: '2000',
					})
					self.disabled = false;
				})
				.then(function(data) {
					// always executed
					console.log(data.data);

					self.disabled = false;

					if (data.code == 200) {
						// alert('保存成功');
						self.$toasted.success( '保存成功', {
							position: 'top-center',
							duration: '2000',
						})
						
						$('#pModal').modal('hide');

					} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) {
						//登录超时
						location.href = "index.html";
					} else {
						Vue.toasted.error( data.message, {
							position: 'top-center',
							duration: '2000',
						})
					}
				});
			//END

		},
		
		getPhoneSetting(callback) {
			let self = this;
		
			//
			this.axios.get(this.GLOBAL.baseURI + 'getPhoneSetting', {
					params: {
						token: self.token,
						dept_id: self.selectedId,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) {
						//重置手机设置
						if(data.data.setting.phone_number != undefined){
							self.phone_setting.phone_number = data.data.setting.phone_number
						}
						
						if (typeof callback == 'function') {
						    callback()
						}
					} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) {
						//登录超时
						// location.href = "index.html";
						self.logoff();
					} 
					else {
						self.$toasted.error( data.message, {
							position: 'top-center',
							duration: '2000',
						})
					}
				});
			//END
		
		},
    	
    	onOutletChangeListener(e) {
    		let self = this;
    		console.log('--- outlet ID->', e)
    		self.outlet_id = e;
    	
    		self.initData();
    	},
    }
}
</script>

<style>
</style>
